import { Fab } from '@mui/material';
import React from 'react'
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export const WhatsappWidget = () => {
    const phoneNumber = "7316914286";
    
    const isMobile = () => {
      return /iPhone|iPad|iPod|Android|BlackBerry|Windows Phone/i.test(navigator.userAgent);
    };
    
    const whatsappLink = isMobile()
      ? `https://wa.me/${phoneNumber}`  // Mobile link
      : `https://web.whatsapp.com/send?phone=${phoneNumber}`;  // Desktop link
  
    return (
        <Fab
        color="success"
        aria-label="WhatsApp"
        href={whatsappLink}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          position: "fixed",
          bottom: {md:20,xs:'10px'},
          right: 20,
          backgroundColor: "#25D366",
          "&:hover": { backgroundColor: "#1EBE5D",  transform: "scale(1.1)", 
            transition: "transform 0.3s ease-in-out", 
          },
          width: {xs: 42,  sm: 50,  md:56},
          height:{xs:42, sm:50, md:56}
        }}
      >
        <WhatsAppIcon sx={{ fontSize: 30, color: "white" }} />
      </Fab>
    );
  };

  export default WhatsappWidget;