import { Box, Drawer, Stack, Typography, Popover,Link } from "@mui/material";
import React, {useState} from "react";
import CloseIcon  from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import CpRegistraions from "../modals/cpRegistrations";
import DevelopersRegistraions from "../modals/developersRegistrations";

export default function MainSideDrawer({open,handleClose=()=>{}}){
    const [cpshowLogin,setCpSignup] = useState(false);
    const [cpshowSignUp,setShowCpSignup] = useState(false);
    const [cpopenInvite,setCpSignupForm] = useState(false);
    const [openDrawer,setOpenDrawer] = useState(false);
    const [devshowLogin,setDevSignup] = useState(false);
    const [devshowSignUp,setShowDevSignup] = useState(false);
    const [devopenInvite,setDevSignupForm] = useState(false);

    
      // Define submenu options
    const ExploresubmenuOptions = [
        {
            name: "How It works",
            onClick: () =>handleClick("/how-it-works")
        },
        {
            name: "Why RealX",
            onClick: () =>handleClick("/why-realx")
        },
       
        {
            name: "About Us",
            onClick: () =>handleClick("/about-us")
            // onClick: () => navigate("/about-us")
        },
      

    ]
  const submenuOptions = [
      {
        name: "Signup As Channel Partner",
        onClick: ()=>setCpSignupForm(true)
  
      },
    {
      name: "Signup As Developer",
      onClick: ()=>setDevSignupForm(true)
    },
  ];

  const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);
  const[exploresubmenuAnchorEl,setexploreSubmenuAnchorEl]= useState(null);

  const handleSubmenuClick = (event) => {
    setSubmenuAnchorEl(event.currentTarget);
  };

  const handleSubmenuClose = () => {
    setSubmenuAnchorEl(null);
  };
  const exploreSubmenuClick=(event)=>{
    setexploreSubmenuAnchorEl(event.currentTarget)
}

  const exploreSubmenuClose = () => {
    setexploreSubmenuAnchorEl(null);
  };


    const navigate = useNavigate();

    const handleClick =(link)=>{
        navigate(link);
        handleClose();
    }

    const tabs = [
        // {
        //     name:"How it works",
        //     link:"/how-it-works"
        // },
        // {
        //     name:"Why RealX",
        //     link:"/why-realx",
        // },
        {
            name:"FAQs",
            link:"/faq"
        },
        // {
        //     name:"About Us",
        //     link:"/about-us"
        // },

        // {
        //     name:"Contact Us",
        //     link:"/contact-us"
        // },
    ]

    return(
        <Drawer anchor="right" open={open} onClose={handleClose}>
            <Box
                sx={{
                    width:"100vw",
                    bgcolor:"#FFF",
                    padding:3
                }}
            >
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Box  sx={{height:{md:54,xs:24},cursor:"pointer"}} component={"img"} src={"/assets/images/logo.png"}></Box>
                        <CloseIcon onClick={handleClose} sx={{width:24,height:24,color:"text.disabled",cursor:"pointer"}} />
                </Stack>
                <CpRegistraions setShowLogin={()=>{setCpSignup(true); setShowCpSignup(true)}} open={cpopenInvite} handleClose={()=>setCpSignupForm(false)}></CpRegistraions>
                <DevelopersRegistraions setShowLogin={()=>{setDevSignup(true); setShowDevSignup(true)}} open={devopenInvite} handleClose={()=>setDevSignupForm(false)}></DevelopersRegistraions>

                <Stack mt={5} spacing={3}>
                    {
                        tabs.map((e)=>{
                            return(
                                <Box>
                                    <Typography  onClick={()=>handleClick(e.link)} variant='headerMenu' sx={{cursor:"pointer"}}>{e.name}</Typography>
                                </Box>
                            )
                        })
                    }
                     <Typography onClick={exploreSubmenuClick} variant='headerMenu' sx={{ cursor: "pointer" }}>Explore RealX</Typography>
                     <Typography onClick={handleSubmenuClick} variant='headerMenu' sx={{ cursor: "pointer" }}>Associate With Us</Typography>
                     <Typography variant='headerMenu' sx={{cursor: "pointer" }}>
                         <Link sx={{textDecoration:"none",color:'inherit'}} href="https://blog.realx.in"><Box  sx={{height:{md:"2vw",xs:"6vw"},cursor:"pointer",minWidth:{md:"0px",xs:"60px"}}} component={"img"} src={"/assets/images/wassup.png"}></Box></Link>
                     </Typography>
                  
                    <Box>
                       {/* Submenu Popover */}
                    <Popover
                        open={Boolean(submenuAnchorEl)}
                        anchorEl={submenuAnchorEl}
                        onClose={handleSubmenuClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Box p={2}>
                            {submenuOptions.map((option, index) => (
                                <Typography
                                    key={index}
                                    variant="body1"
                                    sx={{ fontWeight: 600, cursor: "pointer", padding: "5px", fontWeight: "Normal" }}
                                    onClick={() => {
                                        handleSubmenuClose();
                                        option.onClick(); // Execute the dynamic function
                                    }}
                                >
                                    {option.name}
                                </Typography>
                            ))}
                        </Box>
                    </Popover>
                    <Popover
                            open={Boolean(exploresubmenuAnchorEl)}
                            anchorEl={exploresubmenuAnchorEl}
                            onClose={exploreSubmenuClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <Box p={2}>
                                {ExploresubmenuOptions.map((option, index) => (
                                    <Typography
                                        key={index}
                                        variant="body1"
                                        sx={{ fontWeight: 600, cursor: "pointer", padding: "5px", fontWeight: "Normal" }}
                                        onClick={() => {
                                            exploreSubmenuClose();
                                            option.onClick(); // Execute the dynamic function
                                        }}
                                    >
                                        {option.name}
                                    </Typography>
                                ))}
                            </Box>

                        </Popover>
                    </Box>
                    {/* <Box>
                        <Typography  onClick={()=>handleClick('/marketplace')} variant='headerMenu' sx={{cursor:"pointer"}}>Marketplace</Typography>
                    </Box> */}
                    {/* <Box>
                        <Typography variant='headerMenu' sx={{cursor:"pointer"}}>How To Invest?</Typography>
                    </Box>
                    <Box>
                        <Typography variant='headerMenu' sx={{cursor:"pointer"}}>Blog</Typography>
                    </Box>
                    <Box>
                        <Typography onClick={()=>handleClick('/about-us')} variant='headerMenu' sx={{cursor:"pointer"}}>About Us</Typography>
                    </Box> */}
                    {/* <Box>
                        <Typography variant='headerMenu' sx={{cursor:"pointer"}}>Terms & Conditions</Typography>
                    </Box> */}
                    {/* <Box>
                        <Typography onClick={()=>handleClick('policy')} variant='headerMenu' sx={{cursor:"pointer"}}>Privacy Policy</Typography>
                    </Box> */}
                </Stack>
            </Box>
        </Drawer>
    )
}