import React,{ useEffect, useState} from 'react'
import { Box, Button, Stack, Typography, Popover, Link } from "@mui/material";
// import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
import MainSideDrawer from './mainSideDrawer';
import CustomTabs from '../tabs/customTabs';
import CpRegistraions from '../modals/cpRegistrations';
import DevelopersRegistraions from '../modals/developersRegistrations';
import { EXTERNAL_URLS } from '../../utils/constants';
import ReactGA from 'react-ga4';

const MainHeader = () =>{
    const platformLink = EXTERNAL_URLS.platformLink;
    const [showLogin,setShowCPSignup] = useState(false);
    // const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    // const user = useSelector(state => state.user.data);
    const [transparentHeader,setTransparentHeader] = useState(true);
    const [openDrawer,setOpenDrawer] = useState(false);
    const [selectedTab,setSelectedTab] = useState(-1);
    const [showTerms,setShowTerms] = useState(false);
    const [cpshowLogin,setCpSignup] = useState(false);
    const [cpshowSignUp,setShowCpSignup] = useState(false);
    const [cpopenInvite,setCpSignupForm] = useState(false);
    const [devshowLogin,setDevSignup] = useState(false);
    const [devshowSignUp,setShowDevSignup] = useState(false);
    const [devopenInvite,setDevSignupForm] = useState(false);
    // const [showSignUp,setShowSignUp] = useState(false);

    const { pathname } = useLocation();

      // Define submenu options
  const submenuOptions = [
    {
      name: "Signup As Channel Partner",
     onClick: ()=>setCpSignupForm(true)
    },
    {
      name: "Signup As Developer",
     onClick: ()=>setDevSignupForm(true)

    },
  ];
  const ExploresubmenuOptions=[
    {
        name: "How It works",
    
        onClick:()=>navigate("/how-it-works")
  
      },
    {
      name: "Why RealX",
     onClick: ()=>navigate("/why-realx")
    },
    {
        name: "About Us",
        onClick: ()=>navigate("/about-us")
 
      },
    
  ]

  const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);
  const[exploresubmenuAnchorEl,setexploreSubmenuAnchorEl]= useState(null);

  const handleSubmenuClick = (event) => {
    setSubmenuAnchorEl(event.currentTarget);
  };

  const handleSubmenuClose = () => {
    setSubmenuAnchorEl(null);
  };

  const exploreSubmenuClick=(event)=>{
    setexploreSubmenuAnchorEl(event.currentTarget)
}

  const exploreSubmenuClose = () => {
    setexploreSubmenuAnchorEl(null);
  };

    useEffect(()=>{
        if(pathname !== "/"){
            setTransparentHeader(false);
        }
        else{
            setTransparentHeader(true);
        }
        window.addEventListener("scroll",checkScroll);

        return ()=>window.removeEventListener("scroll",checkScroll);
        // eslint-disable-next-line
    },[pathname]);

    useEffect(()=>{
        console.log(pathname,"----->Current route");
        console.log(tabs.findIndex((e)=>pathname.includes(e.link)),"----->Current tab");
        const index = tabs.findIndex((e)=>pathname.includes(e.link));
        if(index >= 0){
            setSelectedTab(index);
        }
        else{
            setSelectedTab(-1);
        }
     // eslint-disable-next-line
    },[pathname]);

    const checkScroll = () =>{
        // console.log(window.innerHeight,);
        if(window.scrollY>100){
            setTransparentHeader(false);
        }
        else{
            if(pathname === "/"){
                setTransparentHeader(true);
            }
        }
    } 

    const handleClick = (link) =>{
        navigate(link);
    }

    const handleTabClick = (index) =>{
        setSelectedTab(index);
        if(tabs[index]){
            handleClick(tabs[index].link);
        }
    }

    const tabs = [
        // {
        //     name:"How it works",
        //     link:"/how-it-works"
        // },
        // {
        //     name:"Why RealX",
        //     link:"/why-realx",
        // },
        // {
        //     name:"FAQs",
        //     link:"/faq"
        // },
        // {
        //     name:"About Us",
        //     link:"/about-us"
        // },
        // {
        //     name:"Contact Us",
        //     link:"/contact-us"
        // },
    ]

    const handleLoginSignupClick = () => {
        // Log the event to Google Analytics
        ReactGA.event({
          category: 'User Interaction',
          action: 'Clicked on Login/Signup',
          label: 'Login/Signup Button'
        });
    
        // Open the link in a new tab
        window.open(platformLink, '_blank');
      };

    return(
        
        <Box 
            sx={{
                // height:"100px",
                width:"100%",
                // maxWidth:"1920px",
                // position:"fixed",
                left:0,
                display:"block",
                position:"fixed",
                top:transparentHeader?-84:0,
                bgcolor:"#FFF",
                transition:"all 0.25s ease",
                
                zIndex:2,
                boxShadow:transparentHeader?"none":"#00000008 0px 1px 10px"
            }}
        > 
        <MainSideDrawer open={openDrawer} handleClose={()=>setOpenDrawer(false)} />
        <CpRegistraions setShowLogin={()=>{setCpSignup(true); setShowCpSignup(true)}} open={cpopenInvite} handleClose={()=>setCpSignupForm(false)}></CpRegistraions>
        <DevelopersRegistraions setShowLogin={()=>{setDevSignup(true); setShowDevSignup(true)}} open={devopenInvite} handleClose={()=>setDevSignupForm(false)}></DevelopersRegistraions>

        <Box
            sx={{
                px:{lg:17,md:2,xs:4},
                py:2.5,
                maxWidth:"1920px",
                margin:"auto",
                bgcolor:"#FFF",
            }}
        >
            <Stack direction={"row"} spacing={2} sx={{mr:{md:"168px",lg:"64px"}}}>
                <Stack direction={"row"} spacing={{ xs: "20px", sm: "10px", md: "60px", lg: "80px" }} flex={1} alignItems={"center"}>
                        <Box onClick={()=>handleClick('/')} sx={{height:{md:44,xs:"7vw"},cursor:"pointer",minWidth:{md:"100px",xs:"60px"}}}  component={"img"} src={"/assets/images/logo.png"}></Box>
                    </Stack>
                <CustomTabs onClick={(index)=>handleTabClick(index)} tabs={tabs} props={{alignItems:"center",spacing:7,display:{md:"flex",xs:"none"}}} labelProps={{varient:"headerTab",sx:{fontWeight:600}}} activeTab={selectedTab} />
                <Stack sx={{display:{xs:"none",md:"flex",height:"fit-content"},mr:"22px"}} alignItems={"center"} direction={"row"} spacing={{ xs: "10px", sm: "15px", md: "15px", lg: "30px" }}>
                    <Typography onClick={() => navigate("/faq")} variant="body1" sx={{fontWeight:600,cursor:"pointer"}}>FAQs</Typography>
                    <Typography onClick={exploreSubmenuClick} variant="body1" sx={{fontWeight:600,cursor:"pointer"}}>Explore RealX</Typography>
                    <Typography onClick={handleSubmenuClick} variant="body1" sx={{fontWeight:600,cursor:"pointer"}}>Associate With Us</Typography>
                    <Typography sx={{fontWeight:600,cursor:"pointer",textDecoration: "none", color: 'inherit'}}>
                    <Link  sx={{textDecoration:"none",color:'inherit'}} href="https://blog.realx.in"><Box sx={{height:{md:"2vw",xs:"7vw"},cursor:"pointer",minWidth:{md:"0px",xs:"60px"}}} component={"img"} src={"/assets/images/wassup.png"}></Box></Link>
                    </Typography>
                        {/* Submenu Popover */}
                        <Popover
                            open={Boolean(submenuAnchorEl)}
                            anchorEl={submenuAnchorEl}
                            onClose={handleSubmenuClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            disableScrollLock // Prevents the page from shifting
                        >
                            <Box p={2}>
                                {submenuOptions.map((option, index) => (
                                    <Typography
                                        key={index}
                                        variant="body1"
                                        sx={{ fontWeight: 600, cursor: "pointer", padding: "5px", fontWeight: "Normal" }}
                                        onClick={() => {
                                            handleSubmenuClose();
                                            option.onClick(); // Execute the dynamic function
                                        }}
                                    >
                                        {option.name}
                                    </Typography>
                                ))}
                            </Box>
                        </Popover>
                        <Popover
                                open={Boolean(exploresubmenuAnchorEl)}
                                    anchorEl={exploresubmenuAnchorEl}
                                    onClose={exploreSubmenuClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    disableScrollLock // Prevents the page from shifting
                                >
                                    <Box p={2}>
                                        {ExploresubmenuOptions.map((option, index) => (
                                            <Typography
                                                key={index}
                                                variant="body1"
                                                sx={{ fontWeight: 600, cursor: "pointer", padding: "5px", fontWeight: "Normal" }}
                                                onClick={() => {
                                                    exploreSubmenuClose();
                                                    option.onClick(); // Execute the dynamic function
                                                }}
                                            >
                                                {option.name}
                                            </Typography>
                                        ))}
                                    </Box>

                                </Popover>
                    <Button onClick={handleLoginSignupClick} variant='contained' sx={{ ml: 7, display: { md: "block", xs: "none" } }}>
                        <Typography variant="body1" sx={{ fontWeight: 600, cursor: "pointer", lineHeight: 1, paddingLetf: "20px" }}>Login / Signup</Typography>
                    </Button>
                </Stack>
                <MenuIcon onClick={()=>setOpenDrawer(true)} sx={{cursor:"pointer",display:{md:"none",xs:"block"},position:"absolute",right:{xs:"25px"},top:"22px"}} fontSize="medium" />
            </Stack>
        </Box>
        {/* <AccountSetupModal handleClose={()=>setShowSignUp(false)} open={showSignUp}  /> */}
        </Box>
    )
}
export default MainHeader